import React from "react";
import pageStyle from "../../assets/css/page.module.css";
import workStyle from "../../assets/css/work.module.css";
import style from "../../assets/css/main.module.css";
import Menu from "../../components/menu";
import Footer from "../../components/footer";
import HeroPage from "../../components/hero.page";
import Hamburger from "../../components/hamburger";
import { Link } from "gatsby";
import SEO from "../../components/seo"

import imgHeader from "../../assets/img/realisations/lexus_0.jpg";

export default () => (
  <div id="top">
    <Menu />
    <SEO
    title="Lexus | Agence digitale | MDS Digital Agency"
    description="Une assistance quotidienne infaillible. MDS se charge de la mise à jour de l’ensemble des informations sur tous les modèles proposés par Lexus Belgique."
    keywords="Lexus"
    />
    <div className={style.menu_mobile}>
      <Hamburger />
    </div>
    <HeroPage img={imgHeader} />
    <div className={workStyle.info}>

      <div className={workStyle.info_cell}>
        <h3>Services</h3>
        <span className={workStyle.badge}><Link to="/services/mise-a-jour-contenu/">Mise à jour de contenu</Link></span>
          <span className={workStyle.badge}><Link to="/services/copywriting/">Copywriting</Link></span>
          <span className={workStyle.badge}><Link to="/services/referencement/">Référencement</Link></span>
          <span className={workStyle.badge}><Link to="/services/developpement/">Développement</Link></span>
      </div>
      <div className={workStyle.info_cell}>
      <h3>Secteur</h3>
        <p>Automobile</p>
        <h3>Localisation</h3>
        <p>Zaventem</p>

   
      </div>
      <div className={workStyle.info_cell}>
        <h3>Lien </h3>
        <p>
          <a
          className={workStyle.external_link}
            href="http://www.lexus.be/"
            target="_blank"
            rel="noopener noreferrer"
          >
            lexus.be
          </a>
        </p>
      </div>
    </div>

    <article className={pageStyle.article}>
      <h2>Lexus Belgium</h2>
      <p>
      Nous sommes en charge de la <strong>mise à jour récurrente</strong> des informations relatives à l’ensemble des modèles de prestige présentés sur le site de Lexus en Belgique et au Luxembourg (brochures, visuels, textes, options, coordonnées des garages Lexus,...).
      </p>
      <p> 
      Nous créons des pages d'informations pour annoncer des événements futurs, des séries spéciales, des nouveautés, des promotions,...
      </p>
      <p>
      MDS soigne également le <strong>copywriting</strong> et l’<strong>optimisation du référencement naturel</strong> de tout le contenu ajouté sur le site.
            </p>
      <div className={pageStyle.cta}>
      <Link to="/contact/">Contactez-nous pour en savoir plus</Link>
      </div>
    </article>
    <Footer />
  </div>
);
